import React from "react"
import { FaFilm } from "react-icons/fa" 
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, Link } from 'gatsby'
import parseBlogPost from '../parsers/blog-post'
import BlogPost from "../components/BlogPost"
import chunk from "lodash.chunk"

const parseYoutubeVideo = (data) => ({
  videoId:data.videoId,
  publishedAt: new Date(data.publishedAt),
  title: data.title
})

const IndexPage = ({data}) => {
  const lastVideo = parseYoutubeVideo(data.allYoutubeVideo.edges[0].node)
  
  return (
    <Layout>
      <SEO title="Accueil" />
      <div className="columns is-multiline">
        <div className="video video__wrapper column is-full">
          <iframe className="video__iframe" title="Video"src={"https://www.youtube.com/embed/" + lastVideo.videoId}  frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>
        <div className="column is-full has-text-right-tablet has-text-centered-mobile">
          <a href="https://www.youtube.com/@Letemporiseur" className="button">Voir toutes les vidéos</a>
        </div>
        <div className="columns is-multiline">
          {
            data.allMarkdownRemark.nodes.map(
              (item) => {
                const post = parseBlogPost(item)
                return (
                  <div className="column is-half">
                    <Link to={post.link}><BlogPost blogPost={post} excerpt /></Link>
                  </div>
                )
              } 
            )
          }
        </div>
        <div className="column is-full has-text-right-tablet has-text-centered-mobile"><Link to="/blog" className="button">Voir tous les billets</Link></div>
      </div>
    </Layout>
)
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 10) {
      nodes {
        frontmatter {
          title
          Lieu
          date
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 410) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fields {
          slug
        }
        html
        excerpt
      }
      pageInfo {
        perPage
        itemCount
      }
    }
    allYoutubeVideo {
      edges {
        node {
          id
          title
          videoId
          description
          localThumbnail {
            childImageSharp {
              fixed(width: 300) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          publishedAt
        }
      }
    }
  }
`
